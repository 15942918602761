import React from "react";
import {ApiEngine} from "api-engine";
import "./style/stats.scss";

interface StatsProps {
    api: ApiEngine
}

interface StatsState {
    data: any[] | null
    maxCounter: number
    totalCounter: number
    currentDate: Date
    play: boolean
}

export default class Stats extends React.Component<StatsProps, StatsState> {
    playTimeout: any;
    constructor(props: StatsProps) {
        super(props);
        this.state = {
            data: null,
            maxCounter: 0,
            totalCounter: 0,
            currentDate: new Date(),
            play: false
        };
        this.updateStats = this.updateStats.bind(this);
    }

    componentDidMount() {
        const me = this;
        me.updateStats();
    }

    updateStats() {
        const me = this;
        const url = "/api/stats/get";
        const dateStart = new Date();
        dateStart.setMonth(dateStart.getMonth() - 5);
        const dateEnd = me.state.currentDate;
        this.props.api.asyncFetch(url, {method: "POST", body: JSON.stringify({dateStart, dateEnd})}).then((data: any) => {
            let maxCounter = 0;
            let totalCounter = 0;
            data.labelsCounter.forEach((item: any) => {
                if (item.labels_counter > maxCounter) {
                    maxCounter = item.labels_counter;
                }
                totalCounter += item.labels_counter;
            });
            this.setState({data: data.labelsCounter, maxCounter, totalCounter}, () => {
                if (me.state.play) {
                    me.setState({currentDate: new Date(me.state.currentDate.getTime() + 15 * 60 * 1000)}, () => {
                        me.playTimeout = setTimeout(me.updateStats, 10);
                    });
                }
            });
        });
    }



    render() {
        const me = this;
        return <div className="stats">
            <div className="timeline">
                {(() => {
                    const dates = [];
                    const currentDate = new Date();
                    for (let i = 0; i < 15; i++) {
                        const date = new Date(currentDate);
                        date.setDate(currentDate.getDate() - i);
                        dates.push(<div className="timeline__item" key={i} onClick={() => me.setState({currentDate: date}, me.updateStats)}>{date.toLocaleDateString()}</div>);
                    }
                    return dates.reverse();
                })()}
            </div>
            <div className="stats_control">
                {me.state.currentDate.toLocaleString()}: {me.state.maxCounter} / {me.state.totalCounter}
                <button onClick={() => me.setState({play: !me.state.play}, () => {
                        me.playTimeout = setTimeout(me.updateStats, 100);
                })}>{me.state.play ? <i className={"fal fa-stop"}/> : <i className={"fal fa-play"}/>}</button>
            </div>
            { me.state.data ? me.state.data.map((item) => {
                return <div className="stats__item" key={item.id} style={{width: `${15 + item.labels_counter * 1.0 / me.state.maxCounter * 70}%`}}>
                    <div className="stats__item-email">{item.id}</div>
                    <div className="stats__item-counter">{item.labels_counter}</div>
                </div>;
            }) : null}

        </div>;
    }
}