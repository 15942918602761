import React from "react";
import DataSourceFileWidget from "../widget/DataSourceFileWidget";
import {ApiEngine} from "api-engine";
import ModalContainer from "../../../../../../../../../common/layout/modal/ModalContainer";
import DataSourceForm from "../../form/DataSourceForm";
import DataSourceFileForm from "../form/DataSourceFileForm";

interface DataSourceIndexProps {
  dataSource: any;
  project: any;
  api: ApiEngine;
}

interface DataSourceIndexState {
  dataSourceFiles: any[]
  addingDataSourceFile: boolean
}

export default class DataSourceFilesIndex extends React.Component<DataSourceIndexProps, DataSourceIndexState> {

  constructor(_props: DataSourceIndexProps) {
    super(_props);
    this.state = {
      dataSourceFiles: [],
      addingDataSourceFile: false
    };
    this.getDataSourceFiles = this.getDataSourceFiles.bind(this);
  }

  componentDidMount() {
    this.getDataSourceFiles();
  }

  getDataSourceFiles() {
    const me = this;
    const url = `/api/projects/data_sources/${me.props.dataSource.id}/data_source_files/index`;
    me.props.api.asyncFetch(url, {}).then((_dataSourceFiles) => {
      if (_dataSourceFiles.data_source_files)
        me.setState({ dataSourceFiles: _dataSourceFiles.data_source_files}, () => {
        });
    })
  }

  render() {
    const me = this;
    return <div className={"data-source-index"}>
      <div className={"flex row items-center content-center justify-between"}>
        <h3>Файлы</h3>
        <button onClick={() => {
          me.setState({addingDataSourceFile: true})
        }}><span className={"mr-10"}>Добавить файл</span><i className={"fal fa-plus"}/></button>
      </div>
      <br/>
      <div className={"data-source-widgets-list"}>
        { me.state.dataSourceFiles.map((_dataSourceFile, _dataSourceIndex) => {
          return <DataSourceFileWidget key={`data-source-file-${_dataSourceIndex}`}
                                       api={me.props.api}
                                       project = {me.props.project}
                                       dataSource = {me.props.dataSource}
                                       dataSourceFile={_dataSourceFile}
          />
        })}
      </div>

      { me.state.addingDataSourceFile && <ModalContainer onBackDropClick={() => {me.setState({addingDataSourceFile: false}, () => {
        me.getDataSourceFiles();
      })}}><DataSourceFileForm project={me.props.project} api={me.props.api} callback={() => {me.setState({addingDataSourceFile: false}, () => {
        me.getDataSourceFiles();
      })}} dataSource={me.props.dataSource}/></ModalContainer> }
    </div>
  }
}