import React from "react";
import { ApiContext } from "../api/contexts/ApiContext";
import ApiProvider from "../api/providers/ApiProvider";
import Stats from "./Stats";

export default class StatsPage extends React.Component<any, any> {
    render() {
        return <ApiProvider>
            <ApiContext.Consumer>
                {
                    ({api, user}) => {
                        return <Stats api={api!!} />
                    }
                }
            </ApiContext.Consumer>
        </ApiProvider>
    }
}