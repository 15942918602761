import React from "react";
import "./sign-in.scss";
import {ApiContext} from "../../../api/contexts/ApiContext";
import {ApiEngine} from "api-engine";
import { UserContext } from "../../contexts/UserContext";
import User from "../../common/models/User";
import ApiProvider from "../../../api/providers/ApiProvider";
import SignInContentWithToken from "./SignInContent";

interface SignInState {
  login?: string;
  password?: string;
  error?: string;
  loading?: boolean;
}

export default class SignIn extends React.Component<any, SignInState> {

  constructor(_props: any) {
    super(_props);
    this.state = {};
    this.sendData = this.sendData.bind(this);
    this.sendDataUsingToken = this.sendDataUsingToken.bind(this);
  }

  componentDidMount() {

  }

  sendData(api: ApiEngine) {
    let me = this;
    me.setState({loading: true}, () => {
      api.asyncFetchWithRetries("/api/users/auth/sign_in",
          {
            method: "POST",
            body: JSON.stringify({login: me.state.login, password: me.state.password})},
          1,
          false,
          0).then((_res) => {
        // alert(JSON.stringify(_res));
        localStorage.setItem("jwt", _res.jwt);
        document.location.href = "/cabinet";

      }).catch((_err) => {
        me.setState({loading: false, error: _err.toString()})
      })
    });
  }

  sendDataUsingToken(api: ApiEngine, token: string) {
    let me = this;
    me.setState({loading: true}, () => {
      api.asyncFetchWithRetries("/api/auto-auth/sign_in_by_token",
          {
            method: "POST",
            body: JSON.stringify({token: token})},
          1,
          false,
          0).then((_res) => {
        // alert(JSON.stringify(_res));
        localStorage.setItem("jwt", _res.jwt);
        document.location.href = "/cabinet";

      }).catch((_err) => {
        me.setState({loading: false, error: _err.toString()})
      })
    });
  }

  render() {
    const me = this;
    const inputClass = "w-50 flex ml-auto mr-auto mb-15";
    return <div className={"mt-10vh mb-30vh shadow-1mm flex flex-column align-center justify-center items-center w-70 sign-in ml-auto mr-auto pl-20 pr-20 pb-50 border-15"}>
      <h1>Вход</h1>


      <ApiProvider>
        <ApiContext.Consumer>
          {({api, user}) => {
            if (!user)
              return <>
                <input className={inputClass}
                       placeholder={"Номер телефона или email"}
                       type={"text"}
                       onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
                         me.setState({login: _e.currentTarget.value});
                       }}
                />
                <input className={inputClass}
                       placeholder={"Пароль"}
                       type={"password"}
                       onChange={(_e: React.ChangeEvent<HTMLInputElement>) => {
                         me.setState({password: _e.currentTarget.value});
                       }}
                />
                <button
                    onClick={() => {
                      if (api) me.sendData(api); else alert("No api")
                    }}
                    className={`${(me.state.login && me.state.password && !me.state.loading) ? '' : 'disabled'} w-50 flex ml-auto mr-auto`}
                >Войти</button>
                <SignInContentWithToken api = {api}/>
              </>
            else
              return <>

                <button onClick={() => {document.location.href = "/cabinet"}}>В личный кабинет</button>
              </>

          }
          }
        </ApiContext.Consumer>
      </ApiProvider>

      { me.state.error && <p className={"mt-15 error"}>{me.state.error}</p>}
    </div>
  }
}