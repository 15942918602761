import React from 'react';
import logo from './logo.svg';
import './App.css';
import HeaderWithoutUser from "./common/layout/header/without_user/HeaderWithoutUser";
import Logo from "./common/logo/Logo";
import Footer from "./common/layout/footer/Footer";

import {
  createBrowserRouter, Link,
  RouterProvider,
} from "react-router-dom";
import SignIn from "./domains/users/auth/sign_in/SignIn";
import SearchBox from "./common/searchbox/SearchBox";
import Landing from "./domains/landing/common/Landing";
import Cabinet from "./domains/users/cabinet/views/Cabinet";
import { ApiContext } from './domains/api/contexts/ApiContext';
import ApiProvider from "./domains/api/providers/ApiProvider";
import HeaderForUser from "./common/layout/header/for_user/HeaderForUser";
import AboutUs from "./domains/landing/pages/AboutUs";
import PrivacyPolicy from "./domains/landing/pages/PrivacyPolicy";
import Callback from "./domains/landing/callback_hunter/Callback";
import CursorFollower from "./external/cursor-follower/CursorFollower";
import Rates from "./domains/landing/buy/Rates";
import RatesPage from "./domains/landing/buy/RatesPage";
import Stats from './domains/stats/Stats';
import StatsPage from "./domains/stats/StatsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing/>,
  },
  {
    path: "/sign_in",
    element: <SignIn />,
  },
  {
    path: "/cabinet/*",
    element: <Cabinet />,
  },
  {
    path: "/about_us/*",
    element: <AboutUs />,
  },
  {
    path: "/rates/*",
    element: <RatesPage />,
  },
  {
    path: "/privacy_policy/*",
    element: <PrivacyPolicy />,
  },
  {
    path: "/stats/*",
    element: <StatsPage />,
  },
]);

function App() {
  return (
    <>
      <ApiProvider><ApiContext.Consumer>
          {
            ({api, user}) => {
              if (!user) return <HeaderWithoutUser style={{opacity: 0.8, color: "var(--gray_700)"}}>
                {/* <Logo/> */}
                <h1 onClick={() => {document.location.href = "/"}}>вИИзуал</h1>
                {window.innerWidth > 510 &&
                    <span className={"flex flex-row"}>
                        <a href={"/rates"}>Приобрести</a>
                        {/* <p>Кейсы</p> */}
                        {/*<p>Поддержка</p>*/}
                        <a href={"/about_us"}>О нас</a>
                        <p>Новости</p>
                        {/* <p>Обучение</p> */}
                    </span>
                }
                { !user && <a href={"/sign_in"} style={{display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center"}}><span style={{opacity: 0.8}}>войти</span><i style={{color: "#fff"}} className={"fal fa-sign-in"}/></a> }
                { user && <a href={"#"} onClick={() => { localStorage.removeItem("jwt"); document.location.href = "/"} }><i className={"fal fa-sign-out"}/></a> }
                <SearchBox />
              </HeaderWithoutUser>

              return <HeaderForUser>
                {/* <Logo/> */}
                <h1>вИИзуал</h1>
                <SearchBox />
                <div className={"w-100 flex flex-row justify-end pointer"}>
                  <i onClick={() => {document.location.href = "/cabinet"}} className={"fas fa-user ml-auto"} />
                  { user &&
                      <p className={"my-id"}>id:&nbsp;{user.id}</p>
                  }
                </div>
              </HeaderForUser>
            }
          }
      </ApiContext.Consumer></ApiProvider>
      <RouterProvider router={router}/>
      <Footer style={{opacity: 0.4}}>
        <Logo width={20}/>
        <p>вИИзуал</p>
        {/* <p>Юридическая информация</p> */}
        <a href={"/privacy_policy"}>Политика конфиденциальности</a>
        {/* <p>Правила использования</p> */}
        {/* <p>Политика возврата средств</p> */}
      </Footer>
      <Callback/>
      <CursorFollower target={<svg style={{opacity: 0.6, filter: "blur(1px)"}} width="60" height="67" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" width="1" height="6" rx="0.5" fill="#394C72"/>
        <rect x="1" y="1" width="1" height="6" rx="0.5" transform="rotate(-90 1 1)" fill="#394C72"/>
        <rect x="18.9896" y="-0.010437" width="1" height="6" rx="0.5" transform="rotate(89.8006 18.9896 -0.010437)" fill="#394C72" fill-opacity="0.6"/>
        <rect x="0.969543" y="15.9514" width="1" height="6" rx="0.5" transform="rotate(-89.2105 0.969543 15.9514)" fill="#394C72" fill-opacity="0.6"/>
        <rect x="17.9896" y="-0.00695801" width="1" height="6" rx="0.5" transform="rotate(-0.199383 17.9896 -0.00695801)" fill="#394C72" fill-opacity="0.6"/>
        <rect x="1.96942" y="15.9651" width="1" height="6" rx="0.5" transform="rotate(-179.211 1.96942 15.9651)" fill="#394C72" fill-opacity="0.6"/>
        <rect x="18.9999" y="16.0085" width="1" height="5.934" rx="0.5" transform="rotate(-179.507 18.9999 16.0085)" fill="#394C72"/>
        <rect x="19.0085" y="15.0085" width="1" height="6.01877" rx="0.5" transform="rotate(90.4931 19.0085 15.0085)" fill="#394C72"/>
      </svg>
      }/>
    </>
  );
}

export default App;
