import React from "react";
import {ApiEngine} from "api-engine";
import MulticircleSpinner from "../../../../../../../../../common/loaders/spinners/multicircle-spinner/MulticircleSpinner";
import {Route, Routes} from "react-router-dom";
import User from "../../../../../../../common/models/User";
import DataSourceIndex from "../../../data_sources/index/DataSourceIndex";
import Breadcrumbs from "../../../../../../../../../common/breadcrumbs/Breadcrumbs";
import "./styles/project_control.scss";
import DataSourceShow from "../../../data_sources/show/DataSourceShow";
import LearningModelsIndex from "../../../learning_models/index/LearningModelsIndex";
import LabelsIndex from "../../../labels/index/LabelsIndex";
import DetectionsIndex from "../../../detections/index/DetectionsIndex";

interface ProjectControlProps {
  projectId: any
  api: ApiEngine
  user: User
}

interface ProjectControlState {
  project: any | null,
}

export default class ProjectControl extends React.Component<ProjectControlProps, ProjectControlState> {
  mounted = false

  constructor(_props: ProjectControlProps) {
    super(_props);
    this.state = {
      project: null,
    }
    this.getDataAboutProject = this.getDataAboutProject.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return;
    this.mounted = true;
    this.getDataAboutProject();
  }

  getDataAboutProject() {
    const me = this;
    const url = `/api/users/projects/${me.props.projectId}`
    me.props.api.asyncFetch(url, {}).then((_res) => {
      me.setState({project: _res});
    })
  }

  render() {
    const me = this;
    if (!me.state.project) return <MulticircleSpinner/>;
    return <div className={"project-control"}>
      <Routes>
        <Route path={"/data_sources/:dataSourceId/*"}
               element={<DataSourceShow project={me.state.project}/>}
        />
        <Route path={"/"} element={
          <>
            <Breadcrumbs>
              <a href={"/cabinet/projects"}>Проекты</a>
              <i className={"fal fa-angle-right"}/>
              <div className={"breadcrumb-project-name"}>
                <h3>{me.state.project.title}</h3>
                <p>{me.state.project.description}</p>
              </div>
            </Breadcrumbs>
            <div className={"flex row w-90 "} style={{overflowX: "auto"}}>
              { me.props.user && <DataSourceIndex
                project={me.state.project}
                api={me.props.api}
                user={me.props.user}
              /> }
              { !!me.props.user?.canTrainLearningModels &&
                <LearningModelsIndex
                  project={me.state.project}
                  api={me.props.api}
                  user={me.props.user}
                />
              }
              { !!me.props.user && <LabelsIndex
                project={me.state.project}
                api={me.props.api}
                user={me.props.user}
              /> }
              { !!me.props.user?.canTrainLearningModels &&
              <DetectionsIndex
                project={me.state.project}
                api={me.props.api}
                user={me.props.user}
                />
              }
            </div>
          </>
        }/>
      </Routes>
    </div>
  }
}